// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { KeycloakConfig } from "keycloak-angular";

const keycloakConfig: KeycloakConfig = {
    url: 'https://dev.auth.neo.swiss/auth',
    realm: 'neosana',
    clientId: 'angular-client'
};

const basicAuth: Object = {
    username: 'msg-neomp-sor',
    password: 'msg123'
}

export const environment = {
    name: "production",
    settings: {
        production: true,
        LOG_LEVEL: "WARNING",
        BASE_URL: "https://dev.api.neo.swiss",
        BROKER_URL: "https://dev.delta.neo.swiss",
        MEDIA_URL: "https://dev.media.neo.swiss",
        VGR_URL: "https://dev.vgr.neo.swiss",
        API_NATIONS: "https://restcountries.com/v3.1/all?fields=name",
        GM_OFFER_URL: "https://dev.gm.neo.swiss/api",
        REGISTER_MYNEO: "https://dev.register.my.neo.swiss/register",
        COMPANION_URL: "https://companion.neo.swiss",
        PORTAL_CMS_URL: "https://dev.portal.cms.neo.swiss",
        VISITCARD_URL: "https://vc.neo.swiss",
        USE_GOOGLE_ANALYTICS: false,
        DASHBOARD: "https://dev.analytics.neo.swiss",
        KNOWLEDGEBASE: "https://knowledgebase.neo.swiss",
        SERVICEDESK: "https://servicedesk.neo.swiss",
        LIVECHAT_URL: "https://dev.chat.neo.swiss",
        APPOINTMENT_TOOL_URL: "https://dev.meet.neo.swiss/#/",
        PORTAL_API_TOKEN: "c54ffe3e9b3bea0ffe3136e7a0698103495c78d83fddaa409189d0be83350eca226ff1475f38dda93194fd5f7fcd1508c5339238b5337e93a6c8d271e4efb3febc401afb0d44ff93f0557fd3de0fe7668b314267634edcba990b52db6ff22939ecf04cf1f0ff73a26839a2bfddb11abefb16f6a2875b6336526b2b372af0282c",
    },
    keycloak: keycloakConfig,
    basicAuth: basicAuth
};
export const DEFAULT_LANGUAGE = "de";
export const LANGUAGES = ["de", "en", "fr", "it"];
